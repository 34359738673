import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';
import { GlobalService } from 'src/app/admin-portal/core/services/global.service';
import { UserManagementService } from 'src/app/admin-portal/modules/user-management/services/user-management.service';
import { nameValidator } from 'src/app/utils/name-validation.utils';
import { whitespaceValidator } from 'src/app/utils/whitespace-validation.utils';

@Component({
  selector: 'app-dialog-edit-profile',
  templateUrl: './edit-profile-dialog.component.html',
})
export class EditProfileDailogComponent {
  editProfileForm!: FormGroup;
  isButtonDisabled: boolean = true;
  errorMessage: string | null = null;
  isSubmitting: boolean = false;
  userID: string = '';

  private unsubscribe: Subject<void> = new Subject();

  constructor(
    public matDialog: MatDialogRef<EditProfileDailogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private userService: UserManagementService,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.userID = user?.userID;

    this.initializeForm();

    this.editProfileForm.valueChanges.subscribe(() => {
      this.isButtonDisabled =
        this.editProfileForm.invalid || !this.editProfileForm.dirty;
    });

    this.isButtonDisabled = true;
    this.patchFormValues(this.data.element);
  }

  initializeForm(): void {
    this.editProfileForm = this.formBuilder.group({
      name: ['', [Validators.required, whitespaceValidator(), nameValidator]],
      email: [''],
    });
    this.editProfileForm.get('email')?.disable();
  }

  patchFormValues(element: any) {
    this.editProfileForm.patchValue({
      name: element.name,
      email: element.email,
    });
  }

  onSubmit(): void {
    if (this.editProfileForm.valid && !this.isSubmitting) {
      const { name, userRoleID, portolType, associatedAccountID } =
        this.editProfileForm.value;
      const userID = this.userID;

      const updatedUserData = {
        name,
        userID,
        userRoleID,
        portolType,
        associatedAccountID,
      };

      this.userService
        .editUserList(updatedUserData)
        .pipe(takeUntil(this.globalService.componentDestroyed(this)))
        .subscribe({
          next: () => {
            this.isSubmitting = false;
            this.matDialog.close({ success: true });
          },
          error: (errorResponse: any) => {
            this.errorMessage = errorResponse;
            this.isSubmitting = false;
          },
        });
    }
  }

  close() {
    this.matDialog.close();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
